import { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'

function limitValue(prev_str, new_str, options) {
  const { minValue, maxValue, autoAdjustValue } = options
  const prev_value = parseFloat(prev_str)
  const value = parseFloat(new_str)
  if (autoAdjustValue)
    return _.min([_.max([value, minValue || value]), maxValue || value])
  if ((_.isFinite(minValue) && value < minValue) || (_.isFinite(maxValue) && value > maxValue))
    return prev_value
  return value
}

function normalizeValue(value) {
  const [first, ...rest] = _.split(_.replace(`${value}`, ',', '.'), '.')
  return _.isEmpty(rest) ? first : [first, rest.join('')].join('.')
}

function convertToNumberHandler(type, options) {
  return function convertToNumber(value, prev_value) {
    const { allowNull, nullValue } = options
    if (!value)
      return !!allowNull ? nullValue : 0
    const normalized_value = normalizeValue(value)
    const number_value = limitValue(prev_value, normalized_value, options)
    if (!_.isFinite(number_value))
      return !!allowNull ? nullValue : 0
    switch (type) {
      case 'float':
      case 'decimal':
        return number_value
      case 'integer':
      case 'number':
      default:
        return parseInt(number_value)
    }
  }
}
function convertToStringHandler(type, options) {
  return function convertToString(value, prev_value) {
    const { allowNull, nullValue, precision = 2 } = options
    if (!value)
      return !!allowNull ? nullValue : `0`
    const normalized_value = normalizeValue(value)
    const number_value = limitValue(prev_value, normalized_value, options)
    if (normalized_value == '-')
      return `${normalized_value}`
    if (!_.isFinite(number_value))
      return !!allowNull ? nullValue : `0`
    switch (type) {
      case 'float':
      case 'decimal':
        if (_.endsWith(normalized_value, '.'))
          return `${normalized_value}`
        else if (_.includes(normalized_value, '.')) {
          return _.get(`${normalized_value}`.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?$`)), 0)
        }
        return `${parseFloat(number_value)}`
      case 'integer':
      case 'number':
      default:
        return `${parseInt(number_value)}`
    }
  }
}

const useNumberComponent = (inputValue, onChange, type, options) => {
  const { disabled } = options || {}

  const convertToNumber = useCallback(convertToNumberHandler(type, options), [type, options])
  const convertToString = useCallback(convertToStringHandler(type, options), [type, options])

  const [value, setValue] = useState(convertToString(inputValue))
  const number_value = convertToNumber(value, inputValue)

  useEffect(() => {
    if (!_.isEqual(number_value, inputValue))
      !!onChange && onChange(number_value)
  }, [number_value])

  useEffect(() => {
    if (!_.isEqual(number_value, inputValue))
      setValue(convertToString(inputValue, value))
  }, [disabled, inputValue])

  return [disabled ? convertToString(inputValue) : value, useCallback((v) => setValue(convertToString(v, value), [convertToString, value]))]

}

export default useNumberComponent