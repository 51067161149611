import { useModel } from "../../store"
import _ from 'lodash'

function selectConfigKeys(config1, config2) {
  return _.reduce(_.uniq(_.compact(_.flatten([_.keys(config1), _.keys(config2)]))), (acc, key) => _.set(acc, key, !_.isNil(config1?.[key]) ? config1?.[key] : config2?.[key]), {})
}

const usePaymentForm = ({ job_id }) => {
  const [job, jobStatus] = useModel('job', [job_id], {
    single: true,
    populate: [
      'first_step.order.info',
      'first_step.order.job.payment',
      'first_step.order.assigned_transport.reason',
      'first_step.order.assigned_transport.info',
      'first_step.order.payment_type',
      'first_step.order.mode.config.estimated',
      'first_step.order.mode.config.fixed',
      'first_step.order.mode.config.special',
      'first_step.order.noshow.rate_parameter.ridemodeconfig.estimated',
      'first_step.order.noshow.rate_parameter.ridemodeconfig.fixed',
      'first_step.order.noshow.rate_parameter.ridemodeconfig.special',
      'first_step.order.quotation.rate_parameter.ridemodeconfig.estimated',
      'first_step.order.quotation.rate_parameter.ridemodeconfig.fixed',
      'first_step.order.quotation.rate_parameter.ridemodeconfig.special',
      'first_step.order.driver_estimation.rate_parameter.ridemodeconfig.estimated',
      'first_step.order.driver_estimation.rate_parameter.ridemodeconfig.fixed',
      'first_step.order.driver_estimation.rate_parameter.ridemodeconfig.special',
    ],
  })
  const order = job?.first_step?.order
  const payment_type = order?.payment_type
  const estimation = !!payment_type?.bill_passenger ? order?.quotation : order?.driver_estimation
  const hasNoShow = order?.assigned_transport?.status === 'canceled' && _.includes(['not_loaded', 'late_cancel'], order?.assigned_transport?.reason?.identifier)
  const ridemode_config = order?.mode?.config?.[estimation.type] || {}
  const rate_parameter = !!hasNoShow ? order?.noshow?.rate_parameter || estimation?.rate_parameter : estimation?.rate_parameter
  const rateparameter_config = rate_parameter?.ridemodeconfig?.[estimation.type] || {}
  const config = selectConfigKeys(rateparameter_config, ridemode_config)

  const shouldPrefillBase = estimation?.max_rate !== 0 && !!config?.prefill_base
  const handling_duration = !!config?.prefill_handling ? order?.assigned_transport?.info?.effective_handling_duration : 0
  const baseValue = !!hasNoShow ? order?.job?.payment?.base || 0 : _.sum([estimation?.base, estimation?.empty_fee])

  const initialValues = {
    base: _.max([0, !!shouldPrefillBase ? baseValue : 0]),
    driver_package_fee: _.max([0, estimation?.driver_package_fee]),
    duration: _.max([0, order?.info?.duration]),
    night: _.max([0, order?.info?.night]),
    handling_duration: _.max([0, handling_duration]),
    fees: _.max([0, estimation?.fees]),
    prepaid: _.max([0, estimation?.prepaid]),
    option_amount: _.max([0, estimation?.option_amount]),
  }

  const display_config = {
    base: !config.display_duration_field,
    duration: !!config.display_duration_field,
    driver_package_fee: !!config?.display_driver_package_field,
    fees: !!config?.display_fee_field,
    prepaid: !!config?.display_prepaid_field && payment_type?.type != 'deferred',
    handling_duration: !!config.display_handling_field,
    option_amount: !hasNoShow
  }

  const disabled_config = {
    base: !!shouldPrefillBase,
    duration: false,
    driver_package_fee: true,
    fees: false,
    prepaid: false,
    handling_duration: !config?.allow_handling_modification,
    option_amount: true
  }

  const required_config = {
    base: true,
    duration: true
  }

  const related_config = {
    config,
    estimation,
    rate_parameter,
    hasNoShow,
  }

  return [initialValues, { display_config, disabled_config, required_config, related_config }, jobStatus]
}

export default usePaymentForm
