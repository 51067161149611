import { useContext, useCallback } from 'react'
import { ExternalContext } from '../..'

import { useGlobalState } from '../../../../hooks'

const useThemeMode = props => {
  const { useColor, useTheme } = useContext(ExternalContext)
  const [colorScheme, { setColorMode: nativeSetColormode, colorMode: nativeColormode }] = useColor()
  const [colorMode, setStateColorMode] = useGlobalState('persist.colormode', colorScheme)
  const isDarkmode = colorMode === 'dark'

  const setColorMode = useCallback((value) => {
    setStateColorMode(value)
    nativeSetColormode(value)
  }, [nativeSetColormode, setStateColorMode])


  return [{ colorMode, isDarkmode, colorScheme }, { setColorMode, useTheme }]
}

export default useThemeMode